import React from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';

const Indexer = () => {
  return (
    <section id="indexer-hero">
      <Container>
        <Fade duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            <span className="text-color-main">Indexer</span>
          </h1>
        </Fade>
        <iframe
          className="airtable-embed"
          src="https://airtable.com/embed/shrWxpSwlGAd6k8DJ?backgroundColor=indigo&layout=card&viewControls=on"
          frameBorder="0"
          onmousewheel=""
          width="100%"
          height="560"
          style={{ background: '#050505' }}
        />
      </Container>
    </section>
  );
};

export default Indexer;
